import { h } from "preact";
import style from "./style.scss";
import SectionHeading from "../../../components/SectionHeading";
import ZennichiContactForm from "../../../components/ZennichiContactForm";

const faqList = [
  {
    questionText: "印影機能はありますか",
    answerTex: `追加費用なくご利用いただけます。\n\n印影は、契約年月日と氏名が表示されるシンプルなデザインで生成されます 。これにより、従来の賃貸不動産業務における書面での契約ワークフローを変えることなく、電子で契約を締結することが可能です。`,
  },
  {
    questionText: "印紙税は必要ありますか",
    answerTex: (
      <>
        電子契約においては、印紙税は課税されず、コストカットに繋がります。
        <br />
        <br />
        印紙税の課税については、印紙税法第2条に「文書（略）の作成者は、その作成した課税文書につき、印紙税を納める義務がある」との規定がされています。この「作成」という行為は印紙税法基本通達第44条より「紙の書面に書いて交付すること」と定義されているため、電子契約（データ）を締結（送信）することは課税文書の「作成」に該当せず、印紙税は課税されないということになります。
        <br />
        <br />
        参考 :<br />
        e-Gov 「印紙税法」
        <a
          className={style.FaqLink}
          href="https://elaws.e-gov.go.jp/document?lawid=342AC0000000023"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://elaws.e-gov.go.jp/document?lawid=342AC0000000023
        </a>
        <br />
        国税庁「印紙税法基本通達ー第7節 作成者等」
        <a
          className={style.FaqLink}
          href="https://www.nta.go.jp/law/tsutatsu/kihon/inshi/inshi01/07.htm"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.nta.go.jp/law/tsutatsu/kihon/inshi/inshi01/07.htm
        </a>
      </>
    ),
  },
  {
    questionText: "電子署名の有効性はどのように確認できますか",
    answerTex: `電子契約くんでは、発行された契約書の頭紙にて、署名の有効性と署名履歴（署名日時と署名者）を簡単に確認できます。\n\nまた、Adobe Acrobat Readerの電子署名パネルからも確認することが可能です。電子署名パネルでは、文書の署名日時、署名の有効性、署名者、電子署名後に行われた文書の変更履歴などが確認できます。`,
  },
  {
    questionText: "電子署名の本人確認はどのように行われますか",
    answerTex: `メールアドレス認証で本人確認を行っております。\n\n【メールアドレス認証の手順】\n１- あらかじめ、管理画面で任意のパスコードを設定し、契約者に伝えます。\n２- 管理画面から、契約者のメールアドレス宛てに認証メールを送信します。\n３- 契約者はメールに記載された専用のアクセスURLに遷移し、そのページでパスコードを入力することで、認証が完了します。\n\n専用のアクセスURLは、非常に長く複雑な文字列で契約者ごとに自動生成されるので、第三者からの不正アクセスを防止し、安全に本人確認を行うことができます。`,
  },
  {
    questionText: "契約をする仲介会社もアカウントが必要ですか",
    answerTex: `仲介会社が電子契約を行う場合も、アカウントが必要です。`,
  },
  {
    questionText: "今利用できる契約はなんですか",
    answerTex: `不動産賃貸に関わる契約においては、基本的に電子契約を行うことが可能です。\n\n2022年5月18日にデジタル改革関連法案の施行がされたことで、以下の書類のオンライン交付が可能になりました。\n・35条書面(宅建業法)重要事項説明\n・37条書面(宅建業法)契約内容の記載\n・38条書面(借地借家法)定期借家契約\n\nまた、保証委託契約、保険契約に関しては、契約を扱う企業の規定により、電子契約の可否が異なります。`,
  },
];

function PrimaryButton(props) {
  return (
    <a native className={style.PrimaryButtonContainer} href={props.href}>
      <img className={style.PrimaryButtonIcon} src={props.iconSrc} alt="" />
      <p className={style.PrimaryButtonText}>{props.text}</p>
    </a>
  );
}

function InquiryButton(props) {
  return (
    <a
      native
      className={style.InquiryButtonContainer}
      target={(props.target === "_blank" && "_blank") || ""}
      rel={(props.target === "_blank" && "noopener noreferrer") || ""}
      href="#contact"
    >
      <p className={style.InquiryButtonText}>資料請求・お問い合わせ</p>
    </a>
  );
}

function AboutFlowCard(props) {
  return (
    <div className={style.AboutFlowCardContainer}>
      <img className={style.AboutFlowCardIllust} src={props.illustSrc} alt="" />
      <div className={style.AboutFlowCardDescriptionContainer}>
        <p className={style.AboutFlowCardStepText}>{props.stepText}</p>
        <p className={style.AboutFlowCardDescriptionText}>
          <span className={style.AboutFlowCardDescriptionText1st}>
            {props.descriptionText1st}
          </span>
          <span className={style.AboutFlowCardDescriptionText2nd}>
            {props.descriptionText2nd}
          </span>
        </p>
      </div>
    </div>
  );
}

function BenefitCard(props) {
  return (
    <div className={style.BenefitCardContainer}>
      <p className={style.BenefitCardNumber}>{props.number}</p>
      <img className={style.BenefitCardIllust} src={props.illustSrc} alt="" />
      <h3 className={style.BenefitCardHeadingText}>{props.headingText}</h3>
      <p className={style.BenefitCardDescriptionText}>
        {props.descriptionText}
      </p>
    </div>
  );
}

function FAQCard(props) {
  return (
    <div className={style.FAQCardContainer} key={props.key}>
      <h3 className={style.FAQCardQuestionContainer}>
        <span className={style.FAQCardQuestionPrefix}>Q</span>
        <span className={style.FAQCardQuestionText}>{props.questionText}</span>
      </h3>
      <p className={style.FAQCardAnswerContainer}>
        <span className={style.FAQCardAnswerPrefix}>A</span>
        <span className={style.FAQCardAnswerText}>{props.answerText}</span>
      </p>
    </div>
  );
}

function ArticleCard(props) {
  return (
    <a
      native
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
      className={style.ArticleCardContainer}
    >
      <img className={style.ArticleCardImage} src={props.imgSrc} alt="" />
      <div className={style.ArticleCardContent}>
        <h3 className={style.ArticleCardHeadingText}>{props.headingText}</h3>
        <p className={style.ArticleCardDescriptionText}>
          {props.descriptionText}
        </p>
        <p className={style.ArticleCardCTAText}>続きを読む</p>
      </div>
    </a>
  );
}

function CTAAreaDK() {
  return (
    <section className={style.CTAAreaDKSection}>
      <div className={style.CTAAreaDKWrapper}>
        <p className={style.CTAAreaDKCopyText}>
          不動産取引に特化した
          <br className={style.CTAAreaDKCopyTextNewline} />
          電子契約
          <br />
          <span className={style.CTAAreaDKOrangeText}>特別価格</span>
          で始めませんか？
        </p>
        <div className={style.CTAAreaDKButtonContainer}>
          <PrimaryButton
            iconSrc="/assets/denshi_keiyaku/icon/mail_white.svg"
            text="資料請求・お問い合わせ"
            href="#contact"
          />
        </div>
      </div>
    </section>
  );
}

function OutlinedRectangleHeading(props) {
  return (
    <div className={style.OutlinedRectangleHeadingContainer}>
      <p className={style.OutlinedRectangleHeadingSubTitle}>{props.subTitle}</p>
      <h3 className={style.OutlinedRectangleHeadingTitle}>{props.title}</h3>
    </div>
  );
}

function UnderbarTriangleHeading(props) {
  return <h3 className={style.UnderbarTriangleHeading}>{props.title}</h3>;
}

function ContractItem(props) {
  return (
    <div className={style.ContractItemContainer}>
      <div className={style.ContractItemIconContainer}>
        <img
          className={style.ContractItemIconImage}
          src={props.iconSrc}
          alt=""
        />
      </div>
      <h3 className={style.ContractItemTitle}>{props.title}</h3>
      <p className={style.ContractItemCaption}>{props.caption}</p>
    </div>
  );
}

function FixedCTA() {
  return (
    <section className={style.FixedCTA}>
      <div className={style.FixedCTAFollow}>
        <p className={style.FixedCTACopyText}>
          全日会員様限定の
          <span className={style.FixedCTACopyStorong}>特別価格</span>
          ではじめませんか？
        </p>
        <PrimaryButton
          iconSrc="/assets/crm/icon/mail_white.svg"
          text="資料請求・お問い合わせ"
          href="#contact"
        />
      </div>
    </section>
  );
}

const ZennichiDenshiKeiyaku = () => (
  <>
    <section className={style.FirstViewSection}>
      <ol className={style.BreadcrumbsArea}>
        <li className={style.Breadcrumbs}>
          <a className={style.BreadcrumbsItem} href="/zennichi/itandi-bb">
            TOP
          </a>
        </li>
        <li className={style.Breadcrumbs}>
          <a className={style.BreadcrumbsItem} href="">
            電子契約くん
          </a>
        </li>
      </ol>
      <div className={style.FirstViewContainer}>
        <div className={style.FirstViewInnerWrapper}>
          <div className={style.FirstViewLogoArea}>
            <div className={style.FirstViewLogoAreaBg01}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M30 30L30 0L0 -1.31134e-06C16.5685 -5.87108e-07 30 13.4315 30 30Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className={style.FirstViewLogoAreaBg02}>
              <img
                className={style.FirstViewLogoImage}
                src="/assets/service_logo/itandibbplus_DK.svg"
                alt="電子契約くん"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="167"
                height="45"
                viewBox="0 0 167 45"
                fill="none"
              >
                <path
                  d="M0 0H167.31V24.35C167.31 35.3957 158.356 44.35 147.31 44.35H20C8.9543 44.35 0 35.3957 0 24.35V0Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className={style.FirstViewLogoAreaBg03}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.31134e-06 30L0 0L30 -1.31134e-06C13.4315 -5.87108e-07 5.87108e-07 13.4315 1.31134e-06 30Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <div className={style.FirstViewTextWrapper}>
            <h1 className={style.FirstViewCatchCopy}>
              <span className={style.FirstViewCatchCopyText}>
                <strong className={style.FirstViewCatchCopySubBr}>
                  不動産賃貸取引に
                </strong>
                <strong className={style.FirstViewCatchCopySubBr}>
                  特化した
                </strong>
              </span>
              <br />
              <span className={style.FirstViewCatchCopyText}>
                電子契約システム
              </span>
            </h1>
            <div className={style.FirstViewCatchCopySub}>
              契約作業をオンラインで完結
            </div>
            <InquiryButton />
          </div>
          <div className={style.FirstViewVisualContainer}>
            <img
              className={style.FirstViewVisualImageEmblem}
              src="/assets/denshi_keiyaku/photo/DKEmblem.png"
              alt="仲介会社利用率No1 BOXILSaasAWARD2024不動産業界部門1位"
            />
            <img
              className={style.FirstViewVisualImage}
              src="/assets/denshi_keiyaku/photo/DKMockup.png"
              alt="電子契約くん画面イメージ"
            />
          </div>
        </div>
        <div className={style.BgCircle} />
      </div>
      <div className={style.FirstViewAnnotation}>
        ※スマートキャンプ株式会社主催「BOXIL SaaS AWARD
        2024」導入事例セクション不動産業界部門で受賞
        ※リーシング・マネジメント・コンサルティング株式会社「2024年
        賃貸不動産マーケットのお客様動向調査」（2024/03/04）
      </div>
    </section>
    <section className={style.AboutSection}>
      <div className={style.AboutWrapper}>
        <SectionHeading title={`契約作業がオンライン完結`} subTitle="ABOUT" />
        <p className={style.AboutDescriptionText}>
          複雑な手順は一切なし。簡単な操作で、電子契約を行うことができます。
        </p>
        <div className={style.AboutFlowList}>
          <AboutFlowCard
            illustSrc="/assets/denshi_keiyaku/illustration/textfile_with_plus_on_pc.svg"
            stepText="STEP01"
            descriptionText1st="契約書類"
            descriptionText2nd="を登録"
          />
          <img
            className={style.AboutFlowArrow}
            src="/assets/denshi_keiyaku/icon/polygon_down.svg"
            alt=""
          />
          <AboutFlowCard
            illustSrc="/assets/denshi_keiyaku/illustration/textfile_with_pen_on_pc.svg"
            stepText="STEP02"
            descriptionText1st="IT重説&"
            descriptionText2nd="電子署名"
          />
          <img
            className={style.AboutFlowArrow}
            src="/assets/denshi_keiyaku/icon/polygon_down.svg"
            alt=""
          />
          <AboutFlowCard
            illustSrc="/assets/denshi_keiyaku/illustration/mail_on_pc.svg"
            stepText="STEP03"
            descriptionText1st="契約完了"
            descriptionText2nd="通知"
          />
        </div>
      </div>
    </section>
    <section className={style.MotivateSection}>
      <div className={style.MotivateWrapper}>
        <h2 className={style.MotivateHeading}>電子契約を導入するなら今 </h2>
        <p className={style.MotivateDescription}>
          2022年5月18日に改正宅建業法が施行され、完全オンラインでの契約業務の実現が可能になりました。
          <br />
          <br />
          今後益々一般的になってくるであろうオンラインでの契約業務の波に乗り遅れないためにも、今がベストのタイミングです。
        </p>
      </div>
    </section>
    <section className={style.BenefitsSection}>
      <div className={style.BenefitsWrapper}>
        <SectionHeading title={`電子契約のメリット`} subTitle="BENEFITS" />
        <div className={style.BenefitsList}>
          <BenefitCard
            number="01"
            illustSrc="/assets/denshi_keiyaku/illustration/paper_with_pain_balloons.svg"
            headingText="書類不備の防止"
            descriptionText="書類を手書きすることで発生していた、捺印漏れや記入ミス。電子契約なら、これらの差し戻し対応の業務を削減できます。"
          />
          <BenefitCard
            number="02"
            illustSrc="/assets/denshi_keiyaku/illustration/handshake_and_calendar.svg"
            headingText="スピード契約"
            descriptionText="紙の契約では、最短でも1~2日かかる契約業務。電子契約だと郵送の手間がないから、最短当日で完了。急ぎのお客様への対応も可能に。"
          />
          <BenefitCard
            number="03"
            illustSrc="/assets/denshi_keiyaku/illustration/coins_and_down_arrow.svg"
            headingText="コストを削減"
            descriptionText="紙での契約にかかっていた、書類の郵送代や保管場所のコスト、それに伴う人的コストが削減。電子契約で契約にかかる固定費が減少。"
          />
        </div>
      </div>
    </section>
    <CTAAreaDK />
    <section className={style.ValueSection}>
      <div className={style.ValueWrapper}>
        <SectionHeading
          title={`電子契約くんだからできること`}
          subTitle="VALUE"
          color="light"
        />
        <div className={style.ValueItemContainer}>
          <OutlinedRectangleHeading
            subTitle="POINT01"
            title="申込時の情報とシームレスに連携"
          />
          <p className={style.ValueItemDescription}>
            入居希望者様が申込時に入力した情報を、契約時にそのまま署名者情報として利用できます。煩わしいデータ入力がなくなることで業務が効率化。
          </p>
          <div className={style.ValueItemIllustContainer}>
            <img
              className={style.ValueItemIllustImageSP}
              src="/assets/denshi_keiyaku/illustration/drawing_DKMU_connect_small.svg"
              alt="申込時の情報とシームレスに連携"
            />
            <img
              className={style.ValueItemIllustImagePC}
              src="/assets/denshi_keiyaku/illustration/drawing_DKMU_connect.svg"
              alt="申込時の情報とシームレスに連携"
            />
          </div>
        </div>
        <div className={style.ValueItemContainer}>
          <OutlinedRectangleHeading
            subTitle="POINT02"
            title="付帯する契約も幅広くカバー"
          />
          <p className={style.ValueItemDescription}>
            保証委託契約をはじめとした、賃貸借契約に付帯する契約も幅広く扱っています。電子契約と紙での契約を併用するような手間がかかりません。
          </p>
          <div className={style.ValueContractList}>
            <ContractItem
              iconSrc="/assets/denshi_keiyaku/icon/home_with_building.svg"
              title="賃貸借契約"
              caption="※転貸借契約を含む"
            />
            <ContractItem
              iconSrc="/assets/denshi_keiyaku/icon/dollar.svg"
              title="保証委託契約"
            />
            <ContractItem
              iconSrc="/assets/denshi_keiyaku/icon/memo_with_pen.svg"
              title="重要事項説明書"
            />
            <ContractItem
              iconSrc="/assets/denshi_keiyaku/icon/car.svg"
              title="駐車場契約"
            />
            <ContractItem
              iconSrc="/assets/denshi_keiyaku/icon/arrows_in_home.svg"
              title="定期借家契約"
              caption="※2021年4月現在審議中"
            />
            <ContractItem
              iconSrc="/assets/denshi_keiyaku/icon/heart_in_home.svg"
              title="保険契約"
              caption="※一部契約条件付き"
            />
          </div>
          <p className={style.ValueItemCaption}>
            ※契約条件の詳細についてはお問い合わせください
          </p>
        </div>
        <div className={style.ValueItemContainer}>
          <OutlinedRectangleHeading
            subTitle="POINT03"
            title="契約前の確認書類の追加が可能"
          />
          <p className={style.ValueItemDescription}>
            入居時の注意事項など契約書以外の書類を、入居希望者に確認していただくフローの追加ができます。オンラインでも過不足なく情報を伝えることが可能です。
          </p>
          <div className={style.ValueItemIllustContainer}>
            <img
              className={style.ValueItemIllustImageSP}
              src="/assets/denshi_keiyaku/illustration/drawing_DK_before_contract_small.png"
              alt="契約前の確認書類の追加が可能"
            />
            <img
              className={style.ValueItemIllustImagePC}
              src="/assets/denshi_keiyaku/illustration/drawing_DK_before_contract.png"
              alt="契約前の確認書類の追加が可能"
            />
          </div>
        </div>
      </div>
    </section>
    <CTAAreaDK />
    <section className={style.OperationSection}>
      <div className={style.OperationWrapper}>
        <SectionHeading title={`運用イメージ`} subTitle="OPERATION" />
        <div className={style.OperationContentContainer}>
          <UnderbarTriangleHeading title="紙での契約と電子契約のフロー比較" />
          <div className={style.OperationIllustContainer}>
            <img
              className={style.OperationIllustImageSP}
              src="/assets/denshi_keiyaku/illustration/drawing_compare_flow_small.png"
              alt="紙での契約と電子契約のフロー比較"
            />
            <img
              className={style.OperationIllustImagePC}
              src="/assets/denshi_keiyaku/illustration/drawing_compare_flow.png"
              alt="紙での契約と電子契約のフロー比較"
            />
          </div>
        </div>
      </div>
    </section>
    <CTAAreaDK />
    <section className={style.AchievementSection}>
      <div className={style.AchievementWrapper}>
        <SectionHeading title={`導入企業様`} subTitle="USER" />
        <img
          className={style.AchievementLogo}
          src="/assets/denshi_keiyaku/logo/daito_kentaku_leaseng_etc.svg"
          alt="大東建託リーシング株式会社　他"
        />
      </div>
    </section>
    <section className={style.GuaranteeSection}>
      <div className={style.GuaranteeWrapper}>
        <SectionHeading title={`提携保証会社様`} subTitle="GUARANTEE" />
        <ul className={style.GuaranteeCompanyList}>
          <li className={style.GuaranteeCompanyItem}>
            <img
              className={style.GuaranteeCompanyItemLogo}
              src="/assets/denshi_keiyaku/logo/オリコフォレントインシュア.png"
              alt="株式会社オリコフォレントインシュア"
            />
          </li>
          <li className={style.GuaranteeCompanyItem}>
            <img
              className={style.GuaranteeCompanyItemLogo}
              src="/assets/denshi_keiyaku/logo/EPOS.svg"
              alt="株式会社エポスカード"
            />
          </li>
          <li className={style.GuaranteeCompanyItem}>
            <img
              className={style.GuaranteeCompanyItemLogo}
              src="/assets/denshi_keiyaku/logo/NS.png"
              alt="日本セーフティー株式会社"
            />
          </li>
          <li className={style.GuaranteeCompanyItem}>
            <img
              className={style.GuaranteeCompanyItemLogo}
              src="/assets/denshi_keiyaku/logo/J-LEASE.png"
              alt="ジェイリース"
            />
          </li>
          <li className={style.GuaranteeCompanyItemPC}>
            <img
              className={style.GuaranteeCompanyItemLogo}
              src="/assets/denshi_keiyaku/logo/あんしん保証_pc.png"
              alt="あんしん保証"
            />
          </li>
          <li className={style.GuaranteeCompanyItemMobile}>
            <img
              className={style.GuaranteeCompanyItemLogo}
              src="/assets/denshi_keiyaku/logo/あんしん保証_mobile.png"
              alt="あんしん保証"
            />
          </li>
          <li className={style.GuaranteeCompanyItem}>
            <img
              className={style.GuaranteeCompanyItemLogo}
              src="/assets/denshi_keiyaku/logo/GTN.png"
              alt="Global Trust Networks"
            />
          </li>
        </ul>
      </div>
    </section>
    <section className={style.AchievementSection}>
      <div className={style.AchievementWrapper}>
        <SectionHeading title={`認定`} subTitle="ACHIEVEMENT" />
        <div className={style.AchievementContentContainer}>
          <img
            className={style.AchievementContentImage}
            src="/assets/denshi_keiyaku/logo/time_stamp.png"
            alt="認定タイムスタンプ利用登録"
          />
          <div className={style.AchievementContentDescription}>
            <h3 className={style.AchievementContentDescriptionTitle}>
              認定タイムスタンプ
            </h3>
            <p className={style.AchievementContentDescriptionText}>
              電子契約くんは、一般財団法人日本データ通信協会が発行する「認定タイムスタンプ利用登録マーク」を取得。適切な電子契約要件、技術運用、設備などの基準を満たし、「認定タイムスタンプ」を利用しています。
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className={style.KnowledgeSection}>
      <div className={style.KnowledgeWrapper}>
        <SectionHeading
          title={`電子契約に関するお役立ち記事`}
          subTitle="KNOWLEDGE"
        />
        <div className={style.KnowledgeArticleList}>
          <ArticleCard
            href="https://magazine.itandi.co.jp/casestudy/327/"
            imgSrc="/assets/denshi_keiyaku/illustration/knowledge_article_eyecatch_01.png"
            headingText="電子契約くんで、宅建業法改正後まもなく契約業務の完全電子化・最短即日契約を実現！株式会社LS・Capital様"
            descriptionText="「不動産を通じて、物質的・精神的豊かさを追求する」という企業理念のもと、都内で1,100戸ほどを管理する株式会社LS・Capital様。2021年3月にITANDI BB+内見予約くんと申込受付くんを、2022年1月に電子契約くんをご契約いただきました。"
          />
          <ArticleCard
            href="https://magazine.itandi.co.jp/casestudy/321/"
            imgSrc="/assets/denshi_keiyaku/illustration/knowledge_article_eyecatch_02.png"
            headingText="60分かかっていた契約業務が、電子契約くんの活用で15分に！ 株式会社ワールドウィン様"
            descriptionText="都内で不動産の売買・仲介・管理・開発などをおこなう株式会社ワールドウィン様。2021年8月より、いち早く『電子契約くん』をご導入・ご活用いただいておりました。その後2022年5月、ついに宅建業法が改正となりましたが、業務効率にさらなる変化はあったのでしょうか。"
          />

          <ArticleCard
            href="https://magazine.itandi.co.jp/tech-for-kanri/332/?__hstc=129414227.5289ef2cc6839380b9893568a9779afc.1636082453223.1666145037961.1666159691553.329&__hssc=129414227.1.1666159691553&__hsfp=717845591"
            imgSrc="/assets/denshi_keiyaku/illustration/knowledge_article_eyecatch_03.png"
            headingText="【管理会社向け】電子契約実施のためのマニュアルを解説！ 導入に向けたto doとは？"
            descriptionText="2021（令和3）年5月12日に成立した「デジタル改革関連法」において、宅地建物取引業法（以下「宅建業法」）も改正の対象となっており、不動産取引の電子化がいよいよ実現することは既報の通りです。"
          />
          <ArticleCard
            href="https://magazine.itandi.co.jp/tech-for-kanri/362/?__hstc=129414227.5289ef2cc6839380b9893568a9779afc.1636082453223.1666145037961.1666159691553.329&__hssc=129414227.1.1666159691553&__hsfp=717845591"
            imgSrc="/assets/denshi_keiyaku/illustration/knowledge_article_eyecatch_04.png"
            headingText="不動産賃貸取引の電子契約が全面解禁へ！特徴や導入のメリットを解説"
            descriptionText="2017年にIT重説が解禁されて以降、不動産業界で求められてきたのが契約の完全電子化。社会実験を経て、ついに不動産賃貸取引における電子契約が全面解禁される日が目前に迫ってきました。"
          />

          <ArticleCard
            href="https://magazine.itandi.co.jp/tech-for-kanri/340/"
            imgSrc="/assets/denshi_keiyaku/illustration/knowledge_article_eyecatch_05.png"
            headingText="電子契約のセキュリティ対策はできてる？リスクと対策を解説"
            descriptionText="ITANDIが行ったアンケートによると、賃貸入居の契約時に「電子契約を選択したい」と回答したエンドユーザーは73％。政府のDX推進（デジタルトランスフォーメーション）の影響で、多くの業界がIT化を進めているため、ユーザーもあらゆるサービスをオンラインで完結させるようになってきました。 クラウドサイン事業本部 高橋佐和さんにお話を伺いました。"
          />
        </div>
      </div>
    </section>
    <CTAAreaDK />
    <section className={style.FAQSection}>
      <div className={style.FAQWrapper}>
        <SectionHeading title={`よくある質問`} subTitle="FAQ" />
        <div className={style.FAQList}>
          {faqList.map((faq) => (
            <FAQCard
              key={faq.questionText}
              questionText={faq.questionText}
              answerText={faq.answerTex}
            />
          ))}
        </div>
      </div>
    </section>
    <CTAAreaDK />
    <section className="contact-section" id="contact">
      <div className="container-width">
        <div className="contact-container" id="contact">
          <SectionHeading title={`資料請求・お問い合わせ`} subTitle="CONTACT" />
          <div className="contact-form">
            <ZennichiContactForm />
          </div>
        </div>
      </div>
    </section>
    <FixedCTA />
  </>
);

export default ZennichiDenshiKeiyaku;
