import { h } from "preact";
import ZennichiContactForm from "../../../components/ZennichiContactForm";
import SectionHeading from "../../../components/SectionHeadingWithUnderBar";
import FeatureItem from "../../../components/FeatureItem";
import BenefitItem from "../../../components/BenefitItem";
import InterviewCard from "../../../components/InterviewCard";
import OptionCard from "../../../components/OptionCard";
import style from "./style.scss";

function RecordItem(props) {
  return (
    <div className={style.RecordItemWrapper}>
      <div className={style.RecordItemImageContainer}>
        <img
          className={style.RecordItemImage}
          src={props.imageSrc}
          alt={props.imageAlt}
        />
      </div>
      <div className={style.RecordItemDescriptionContainer}>
        <p className={style.RecordItemTitle}>{props.title}</p>
        <p className={style.RecordItemNumber}>
          <span className={style.RecordItemNumberSub}>{props.sub}</span>
          {props.number}
          <span className={style.RecordItemNumberUnit}>{props.unit}</span>
        </p>
        <small className={style.RecordItemCaption}>{props.Caption}</small>
      </div>
    </div>
  );
}

function InquiryButton(props) {
  return (
    <a
      native
      className={style.InquiryButtonContainer}
      target={(props.target === "_blank" && "_blank") || ""}
      rel={(props.target === "_blank" && "noopener noreferrer") || ""}
      href="#contact"
    >
      <p className={style.InquiryButtonText}>資料請求・お問い合わせ</p>
    </a>
  );
}

function PrimaryButton(props) {
  return (
    <a native className={style.PrimaryButtonContainer} href={props.href}>
      <img className={style.PrimaryButtonIcon} src={props.iconSrc} alt="" />
      <p className={style.PrimaryButtonText}>{props.text}</p>
    </a>
  );
}

function CTAArea() {
  return (
    <section className={style.CTAAreaSection}>
      <div className={style.CTAAreaWrapper}>
        <p className={style.CTAAreaCopyText}>
          利用数No.1の
          <br className={style.CTAAreaCopyTextNewline} />
          入居申込サービスを
          <br />
          <span className={style.CTAAreaCopyStorong}>特別価格</span>
          で始めませんか？
        </p>
        <div className={style.CTAAreaButtonContainer}>
          <PrimaryButton
            iconSrc="/assets/moushikomi_uketsuke/download_MUgreen.svg"
            text="資料請求・お問い合わせ"
            href="#contact"
          />
        </div>
      </div>
    </section>
  );
}

function FixedCTA() {
  return (
    <section className={style.FixedCTA}>
      <div className={style.FixedCTAFollow}>
        <p className={style.FixedCTACopyText}>
          全日会員様限定の
          <span className={style.FixedCTACopyStorong}>特別価格</span>
          ではじめませんか？
        </p>
        <PrimaryButton
          iconSrc="/assets/moushikomi_uketsuke/download_MUgreen.svg"
          text="資料請求・お問い合わせ"
          href="#contact"
        />
      </div>
    </section>
  );
}

const ZennichiMoushikomiUketsuke = () => (
  <>
    <section className={style.FirstViewSection}>
      <ol className={style.BreadcrumbsArea}>
        <li className={style.Breadcrumbs}>
          <a className={style.BreadcrumbsItem} href="/zennichi/itandi-bb">
            TOP
          </a>
        </li>
        <li className={style.Breadcrumbs}>
          <a className={style.BreadcrumbsItem} href="">
            申込受付くん
          </a>
        </li>
      </ol>
      <div className={style.FirstViewContainer}>
        <div className={style.FirstViewInnerWrapper}>
          <div className={style.FirstViewLogoArea}>
            <div className={style.FirstViewLogoAreaBg01}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M30 30L30 0L0 -1.31134e-06C16.5685 -5.87108e-07 30 13.4315 30 30Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className={style.FirstViewLogoAreaBg02}>
              <img
                className={style.FirstViewLogoImage}
                src="/assets/service_logo/itandibbplus_MU.svg"
                alt="申込受付くん"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="167"
                height="45"
                viewBox="0 0 167 45"
                fill="none"
              >
                <path
                  d="M0 0H167.31V24.35C167.31 35.3957 158.356 44.35 147.31 44.35H20C8.9543 44.35 0 35.3957 0 24.35V0Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className={style.FirstViewLogoAreaBg03}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.31134e-06 30L0 0L30 -1.31134e-06C13.4315 -5.87108e-07 5.87108e-07 13.4315 1.31134e-06 30Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <div className={style.FirstViewTextWrapper}>
            <h1 className={style.FirstViewCatchCopy}>
              <span className={style.FirstViewCatchCopyText}>
                オンラインで簡単に
              </span>
              <br />
              <span className={style.FirstViewCatchCopyText}>
                入居申込を管理
              </span>
            </h1>
            <div className={style.FirstViewCatchCopySub}>
              <strong className={style.FirstViewCatchCopySubBr}>
                申込や審査の
              </strong>
              <strong className={style.FirstViewCatchCopySubBr}>
                やり取りをWeb化
              </strong>
            </div>
            <div className={style.FirstViewButtonWrapper}>
              <InquiryButton />
              <a
                native
                className={style.ReservationButtonContainer}
                rel="nofollow"
                href={`https://${ITANDI_BB_HOST}/top`}
              >
                <p className={style.ReservationButtonText}>
                  入居申込したい
                  <br />
                  仲介会社様はこちら
                </p>
              </a>
            </div>
          </div>
          <div className={style.FirstViewVisualContainer}>
            <img
              className={style.FirstViewVisualImageEmblem}
              src="/assets/moushikomi_uketsuke/MUEmblem.png"
              alt="仲介会社利用率No1"
            />
            <img
              className={style.FirstViewVisualImage}
              src="/assets/moushikomi_uketsuke/MUMockup.png"
              alt="申込受付くん画面イメージ"
            />
          </div>
        </div>
        <div className={style.BgCircle} />
      </div>
      <div className={style.FirstViewAnnotation}>
        ※リーシング・マネジメント・コンサルティング株式会社「2024年
        賃貸不動産マーケットのお客様動向調査」（2024/03/04）
      </div>
    </section>
    <section className={style.AbstractSection}>
      <img
        className={style.AbstractImage}
        src="/assets/moushikomi_uketsuke/moushikomi_conseptual.png"
        alt="Bukkakun conseptual"
      />
    </section>
    <section className={style.FeaturesSection}>
      <div className={style.FeaturesWrapper}>
        <SectionHeading title="機能詳細" serviceName="MU" />
        <div className={style.FeaturesContainer}>
          <FeatureItem
            imgSrc="/assets/moushikomi_uketsuke/detail-icon01.png"
            imgAlt=""
            title={`入力不備を\n自動でチェック`}
            description="Web申込書の入力不備を自動でチェックします。戻し作業が何度も発生することなく、タイムロスを防ぎます。"
          />
          <FeatureItem
            imgSrc="/assets/moushikomi_uketsuke/detail-icon02.png"
            imgAlt=""
            title={`申込情報を\nリアルタイムで共有`}
            description={`申込状況や内容は即時反映。申込者、管理会社、仲介会社、保証会社のどこがボールを持っているかをすぐ確認できます。関係会社や顧客とのコミュニケーションコストを削減します。`}
          />
          <FeatureItem
            imgSrc="/assets/moushikomi_uketsuke/detail-icon03.png"
            imgAlt=""
            title="提携保証会社も多数"
            description="約80社の大手保証会社様と提携、システム連携しています。"
          />
          <FeatureItem
            imgSrc="/assets/moushikomi_uketsuke/detail-icon04.png"
            imgAlt=""
            title={`チャットメッセージで\n電話いらず`}
            description="仲介会社にチャットメッセージを送れるので、何度も電話かける手間がなく、内容の履歴も残るので安心。"
          />
          <FeatureItem
            imgSrc="/assets/moushikomi_uketsuke/detail-icon05.png"
            imgAlt=""
            title={`申込フォームは\nカスタマイズ可能`}
            description="申し込みフォームは導入会社様に合わせて、独自に項目をカスタマイズできます。"
          />
        </div>
      </div>
    </section>
    <section className={style.MovieSection}>
      <SectionHeading title="サービス紹介動画" serviceName="MU" />
      <div className={style.MovieContainer}>
        <iframe
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen="true"
          frameBorder={0}
          height={315}
          src="https://www.youtube.com/embed/9DWmSGZp3tg"
          width={560}
        />
      </div>
    </section>
    <section className={style.BenefitSection}>
      <div className={style.BenefitWrapper}>
        <SectionHeading title="導入効果" serviceName="MU" />
        <BenefitItem
          serviceName="MU"
          imgSrc="/assets/moushikomi_uketsuke/performance01.png"
          imgAlt=""
          title="導入効果 1"
          copy={`紙の申込書管理を削減`}
          description={`Web申込機能により、無駄な紙のコストを削減します。\n必要な時は、PDFから紙に印刷することも可能です。`}
        />
        <BenefitItem
          serviceName="MU"
          imgSrc="/assets/moushikomi_uketsuke/performance02.png"
          imgAlt=""
          title="導入効果 ２"
          copy={`チャット機能により電話コストを削減`}
          description={`チャットメッセージで進捗管理ややりとりをすることで、電話でのやりとりをなくし、コストを削減できます。`}
        />
        <BenefitItem
          serviceName="MU"
          imgSrc="/assets/moushikomi_uketsuke/performance03.png"
          imgAlt=""
          title="導入効果 ３"
          copy={`申込進捗確認の時間を大幅削減`}
          description={`申込状況が一目でわかるので、どこで申込が止まっているかなどすぐ確認できます。電話で確認する時間が必要ありません。`}
        />
      </div>
    </section>
    <CTAArea />
    <section className={style.InterviewSection} id="interview">
      <div className={style.InterviewLayout}>
        <SectionHeading title="導入インタビュー" serviceName="MU" />
        <div className={style.InterviewCardList}>
          <InterviewCard
            href="https://itanzine.itandi.co.jp/interview/fujikensetsu-shinjuku"
            imgSrc="/assets/itandi_bb/fujikensetsu.jpg"
            imgAlt="株式会社富士建設工業新宿支店様"
            title="お客様ファーストでIT化を推進し、内見予約くんと申込受付くんを導入！株式会社富士建設工業新宿支店様"
          />
          <InterviewCard
            href="https://itanzine.itandi.co.jp/interview/tokyo-tatemono"
            imgSrc="/assets/itandi_bb/toutatesama-1.jpg"
            imgAlt="東京建物不動産販売株式会社様"
            title="イタンジの一気通貫サービスを活用しリモート勤務を実現！コロナショックにも適応した東京建物不動産販売株式会社様"
          />
          <InterviewCard
            href="https://magazine.itandi.co.jp/casestudy/380/"
            imgSrc="/assets/itandi_bb/arz-planning.jpg"
            imgAlt="有限会社アルズプランニング様"
            title="北海道で初めて『ITANDI BB+』を導入し成果を実感。有限会社アルズプランニング様"
          />
        </div>
        <div className={style.InterviewLinkContainer}>
          <a
            native
            className={style.InterviewLink}
            href="https://itanzine.itandi.co.jp/tag/導入インタビュー"
            target="_blank"
            rel="noopener noreferrer"
          >
            全ての導入インタビューを見る
          </a>
        </div>
      </div>
    </section>
    <section className={style.RecordSection}>
      <div className={style.RecordWrapper}>
        <SectionHeading title="実績" serviceName="MU" />
        <div className={style.RecordContainer}>
          <RecordItem
            imageSrc="/assets/moushikomi_uketsuke/MULP_icon_buildings.svg"
            imageAlt=""
            title="ITANDI BB+ 導入企業数"
            number="2,840"
            unit="社"
            Caption="※2024年1月末時点"
          />
          <RecordItem
            imageSrc="/assets/moushikomi_uketsuke/MULP_icon_mobile.svg"
            imageAlt=""
            title="年間Web申込件数"
            sub="約"
            number="107"
            unit="万件"
            Caption="※2023年4月1日～2024年3月31日"
          />
          <RecordItem
            imageSrc="/assets/moushikomi_uketsuke/MULP_icon_house_money.svg"
            imageAlt=""
            title="連携保証会社数"
            sub="約"
            number="80"
            unit="社"
            Caption="※2024年2月時点"
          />
        </div>
      </div>
    </section>
    <section className={style.AchievementCompanySection}>
      <div className={style.AchievementCompanyWrapper}>
        <SectionHeading title="導入企業様" serviceName="MU" />
        <ul className={style.AchievementCompanyList}>
          <li className={style.AchievementCompanyItem}>
            <img
              className={style.AchievementCompanyLogo}
              src="/assets/moushikomi_uketsuke/cl_logo01.png"
              alt="大東建託"
            />
          </li>
          <li className={style.AchievementCompanyItem}>
            <img
              className={style.AchievementCompanyLogo}
              src="/assets/moushikomi_uketsuke/cl_logo03.png"
              alt="メイクス"
            />
          </li>
          <li className={style.AchievementCompanyItem}>
            <img
              className={style.AchievementCompanyLogo}
              src="/assets/moushikomi_uketsuke/cl_logo04.png"
              alt="株式会社ナミキ"
            />
          </li>
          <li className={style.AchievementCompanyItem}>
            <img
              className={style.AchievementCompanyLogo}
              src="/assets/moushikomi_uketsuke/cl_logo07.png"
              alt="株式会社市萬"
            />
          </li>
          <li className={style.AchievementCompanyItem}>
            <img
              className={style.AchievementCompanyLogo}
              src="/assets/moushikomi_uketsuke/cl_logo06.png"
              alt="三井ホームエステート"
            />
          </li>
          <li className={style.AchievementCompanyItem}>
            <img
              className={style.AchievementCompanyLogo}
              src="/assets/moushikomi_uketsuke/cl_logo05.png"
              alt="プレサンスコーポレーション"
            />
          </li>
          <li className={style.AchievementCompanyItem}>
            <img
              className={style.AchievementCompanyLogo}
              src="/assets/moushikomi_uketsuke/cl_logo08.png"
              alt="明光トレーディング"
            />
          </li>
          <li className={style.AchievementCompanyItem}>
            <img
              className={style.AchievementCompanyLogo}
              src="/assets/companies_logo/daikyo_logo_kumi_daikyo_anabuki_realestate_big_yoko.png"
              alt="大京穴吹不動産"
            />
          </li>
        </ul>
      </div>
    </section>
    <section className={style.AchievementCompanySection}>
      <div className={style.AchievementCompanyWrapper}>
        <SectionHeading title="提携保証会社様" serviceName="MU" />
        <ul className={style.AchievementCompanyList}>
          <li className={style.AchievementCompanyItem}>
            <img
              className={style.AchievementCompanyLogo}
              src="/assets/moushikomi_uketsuke/partner_logo01.png"
              alt="Partner logo01"
            />
          </li>
          <li className={style.AchievementCompanyItem}>
            <img
              className={style.AchievementCompanyLogo}
              src="/assets/moushikomi_uketsuke/partner_logo02.png"
              alt="Partner logo02"
            />
          </li>
          <li className={style.AchievementCompanyItem}>
            <img
              className={style.AchievementCompanyLogo}
              src="/assets/moushikomi_uketsuke/partner_logo03.png"
              alt="Partner logo03"
            />
          </li>
          <li className={style.AchievementCompanyItem}>
            <img
              className={style.AchievementCompanyLogo}
              src="/assets/moushikomi_uketsuke/partner_logo05.png"
              alt="Partner logo05"
            />
          </li>
          <li className={style.AchievementCompanyItem}>
            <img
              className={style.AchievementCompanyLogo}
              src="/assets/moushikomi_uketsuke/partner_logo06.png"
              alt="Partner logo06"
            />
          </li>
        </ul>
      </div>
    </section>
    <section className={style.OptionSection}>
      <div className={style.OptionWrapper}>
        <SectionHeading
          title="合わせて使うと便利なオプション"
          serviceName="MU"
        />
        <div className={style.OptionContainer}>
          <OptionCard
            href="/zennichi/bukkaku/"
            logoSrc="/assets/naiken_yoyaku/bukkakun.png"
            productName="ぶっかくん"
            title="物確対応を自動化して業務効率アップ"
            description="物件確認の自動応答システム。定休日も時間外も物確電話に24時間365日対応。"
            tagKanri={true}
            tagChukai={false}
          />
          <OptionCard
            href="/zennichi/naiken-yoyaku/"
            logoSrc="/assets/bukkaku/naikenyoyakukun.png"
            productName="内見予約くん"
            title="24時間365日オンラインでいつでも内見受付"
            description="内見予約のWeb受付システム。電話・FAXの通信費や人件費をカットし、内見の機会損失をゼロに。"
            tagKanri={true}
            tagChukai={false}
          />
        </div>
      </div>
    </section>
    <section className={style.ContactSection}>
      <div className={style.ContactWrapper} id="contact">
        <SectionHeading title="資料請求" serviceName="MU" />
        <ZennichiContactForm />
      </div>
    </section>
    <FixedCTA />
  </>
);

export default ZennichiMoushikomiUketsuke;
